.alert {
    position: fixed;
    z-index: 1060;
    right: 15px;
    top: 60px;
    min-height: 30px;
    width: 320px;
    border: 0;
    border-radius: $border-radius-small;
    color: $white-color;
    padding: 0.6rem 0.25rem 0.6rem 1rem;
    margin-bottom: 0.4rem;
    margin-top: 0.4rem;
    &.alert-relative {
        position: relative;
        width: 100%;
        right: 0;
        top: 0;
        z-index: 3;
        margin-bottom: 10px;
    }
    &.alert-success {
        background-color: $success-color;
        //color: $success-color;
        border: 1px solid $success-color;
    }
    &.alert-danger {
        background-color: $danger-color;
        //color: $danger-color;
        border: 1px solid $danger-color;
    }
    &.alert-warning {
        background-color: lighten($warning-color, 5%);
    }
    &.alert-info {
        background-color: lighten($info-color, 5%);
    }
    &.alert-primary {
        background-color: lighten($primary-color, 5%);
    }
    i.fa,
    i.now-ui-icons {
        font-size: 20px;
    }
    .close {
        &,
        &:not(:disabled):not(.disabled):hover,
        &:not(:disabled):not(.disabled):focus {
            color: $white-color;
        }
        opacity: 0.9;
        text-shadow: none;
        line-height: 0;
        outline: 0;
        span {
            font-weight: 400;
            font-size: 36px;
            top: 50%;
            left: 0;
            position: absolute;
        }
    }
    span[data-notify="icon"] {
        font-size: 22px;
        display: block;
        left: 19px;
        position: absolute;
        top: 50%;
        margin-top: -11px;
    }
    button.close {
        position: absolute;
        right: 10px;
        top: 50%;
        margin-top: -18px;
        width: 25px;
        height: 25px;
        padding: 3px;
    }
    .close~span {
        display: block;
        max-width: 89%;
    }
    &.alert-with-icon {
        padding-left: 65px;
    }
}

.alert .close,
.alert .close:not(:disabled):not(.disabled):hover,
.alert .close:not(:disabled):not(.disabled):focus {
    color: inherit;
}

.bg-red {
    background-color: #f8d7da !important;
}

@media (max-width: 767px) {
    .alert {
        width: 90%;
    }
}