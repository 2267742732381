button,
input,
optgroup,
select,
textarea {
  font-family: $sans-serif-family;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: $font-weight-normal;
}

a {
  color: $primary-color;
  &:hover,
  &:focus {
    color: $primary-color;
  }
}
h1,
.h1 {
  font-size: $font-size-h1;
  line-height: 1.15;
  margin-bottom: $margin-base-vertical * 2;

  small {
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    opacity: 0.8;
  }
}
h2,
.h2 {
  font-size: $font-size-h2;
  margin-bottom: $margin-base-vertical * 2;
}
h3,
.h3 {
  font-size: $font-size-h3;
  margin-bottom: $margin-base-vertical * 2;
  line-height: 1.4em;
}
h4,
.h4 {
  font-size: $font-size-h4;
  line-height: 1.45em;
  margin-top: $margin-base-vertical * 2;
  margin-bottom: $margin-base-vertical;

  & + .category,
  &.title + .category {
    margin-top: -10px;
  }
}
h5,
.h5 {
  font-size: $font-size-h5;
  line-height: 1.4em;
  margin-bottom: 15px;
}
h6,
.h6 {
  font-size: $font-size-h6;
  font-weight: $font-weight-bold;
  text-transform: uppercase;
}
p {
  &.description {
    font-size: 1.14em;
  }
}

// i.fa{
//     font-size: 18px;
//     position: relative;
//     top: 1px;
// }

.title {
  font-weight: $font-weight-bold;

  &.title-up {
    text-transform: uppercase;

    a {
      color: $black-color;
      text-decoration: none;
    }
  }
  & + .category {
    margin-top: -10px;
  }
}

.description,
.card-description,
.footer-big p,
.card .footer .stats {
  color: $dark-gray;
  font-weight: $font-weight-light;
}
.category,
.card-category {
  text-transform: capitalize;
  font-weight: $font-weight-normal;
  color: $dark-gray;
  font-size: $font-size-mini;
}

.card-category {
  font-size: $font-size-h6;
}

.text-primary,
a.text-primary:focus,
a.text-primary:hover {
  color: $brand-primary !important;
}
.text-info,
a.text-info:focus,
a.text-info:hover {
  color: $brand-info !important;
}
.text-success,
a.text-success:focus,
a.text-success:hover {
  color: $brand-success !important;
}
.text-warning,
a.text-warning:focus,
a.text-warning:hover {
  color: $brand-warning !important;
}
.text-danger,
a.text-danger:focus,
a.text-danger:hover {
  color: $brand-danger !important;
}

.text-gray,
a.text-gray:focus,
a.text-gray:hover {
  color: $light-gray !important;
}

.blockquote {
  border-left: none;
  border: 1px solid $default-color;
  padding: 20px;
  font-size: $font-size-blockquote;
  line-height: 1.8;

  small {
    color: $default-color;
    font-size: $font-size-small;
    text-transform: uppercase;
  }

  &.blockquote-primary {
    border-color: $primary-color;
    color: $primary-color;

    small {
      color: $primary-color;
    }
  }

  &.blockquote-danger {
    border-color: $danger-color;
    color: $danger-color;

    small {
      color: $danger-color;
    }
  }

  &.blockquote-white {
    border-color: $opacity-8;
    color: $white-color;

    small {
      color: $opacity-8;
    }
  }
}
.full-page-content {
  padding-top: 100px;
}
.input-group-text {
  background: #f2f2f2 !important;
  border: 0 !important;
  border-radius: 0 !important;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  box-shadow: none;
  border-right: 2px solid #dc3545 !important;
}

.was-validated .form-control:valid:focus,
.form-control.is-valid:focus {
  box-shadow: none;
  border-right: 2px solid #28a745 !important;
}
.change-number {
  position: absolute;
  top: 0;
  right: 5px;
  cursor: pointer;
}

input[type="text"]:disabled {
  background-color: #f2f2f2;
}
.resend-otp {
  text-align: right;
  padding: 10px 0 0 0;
  display: block;
  cursor: pointer;
}
.panel-header {
  height: 60px !important;
  padding-top: 0 !important;
  background: $primary-color !important;
}
.sidebar-color {
  background: $primary-color !important;
}

.logout {
  cursor: pointer;
}

.color-black {
  color: #000 !important;
}
.text-camelcase {
  text-transform: capitalize;
  font-weight: normal;
}
