.chat-area {
    display: flex;
    /* align-items: center;
    justify-content: center; */
    background: #f1f1f1;
    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
    font-size: 1em;
    letter-spacing: 0.1px;
    color: #32465a;
    text-rendering: optimizeLegibility;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-font-smoothing: antialiased;
}

#frame {
    width: 100%;
    max-width: 100%;
    background: #e6eaea;
}

#frame .content {
    float: right;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding: 0;
}

#frame .content .contact-profile {
    width: 100%;
    background: #f5f5f5;
    padding: 10px;
}

#frame .content .messages {
    height: auto;
    min-height: 300px;
    overflow-y: scroll;
    overflow-x: hidden;
    background: #dedede;
}

#frame .content .messages::-webkit-scrollbar {
    width: 8px;
    background: transparent;
}

#frame .content .messages::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.3);
}

#frame .content .messages ul li {
    display: inline-block;
    clear: both;
    float: left;
    margin: 15px 15px 5px 15px;
    width: calc(100% - 25px);
    font-size: 0.9em;
}

#frame .content .messages ul li:nth-last-child(1) {
    margin-bottom: 20px;
}

#frame .content .messages ul li.sent img {
    margin: 6px 8px 0 0;
}

#frame .content .messages ul li.sent p {
    background: #435f7a;
    color: #f5f5f5;
}

#frame .content .messages ul li.replies img {
    float: right;
    margin: 6px 0 0 8px;
}

#frame .content .messages ul li.replies p {
    background: #f5f5f5;
    float: right;
}

#frame .content .messages ul li img {
    width: 30px;
    border-radius: 50%;
    float: left;
}

#frame .content .messages ul li p {
    display: inline-block;
    padding: 10px 15px;
    border-radius: 20px;
    max-width: 205px;
    line-height: 130%;min-width:100px;
}

@media screen and (min-width: 735px) {
    #frame .content .messages ul li p {
        max-width: 300px;
    }
}

#frame .content .message-input {
    bottom: 0;
    width: 100%;
    z-index: 99;
}

#frame .content .message-input .wrap {
    position: relative;
}

#frame .content .message-input .wrap input {
    font-family: "proxima-nova", "Source Sans Pro", sans-serif;
    float: left;
    border: none;
    width: calc(100% - 50px);
    padding: 12px 32px 12px 8px;
    font-size: 1em;
    color: #32465a;
}

@media screen and (max-width: 735px) {
    #frame .content .message-input .wrap input {
        padding: 15px 32px 16px 8px;
    }
}

#frame .content .message-input .wrap input:focus {
    outline: none;
}

#frame .content .message-input .wrap .attachment {
    position: absolute;
    right: 60px;
    z-index: 4;
    margin-top: 10px;
    font-size: 1.1em;
    color: #435f7a;
    opacity: 0.5;
    cursor: pointer;
}

@media screen and (max-width: 735px) {
    #frame .content .message-input .wrap .attachment {
        margin-top: 17px;
        right: 65px;
    }
}

#frame .content .message-input .wrap .attachment:hover {
    opacity: 1;
}

#frame .content .message-input .wrap button {
    float: right;
    border: none;
    width: 50px;
    padding: 12px 0;
    cursor: pointer;
    background: #32465a;
    color: #f5f5f5;
}

@media screen and (max-width: 735px) {
    #frame .content .message-input .wrap button {
        padding: 16px 0;
    }
}

#frame .content .message-input .wrap button:hover {
    background: #435f7a;
}

#frame .content .message-input .wrap button:focus {
    outline: none;
}

.main-panel div > .content {
    min-height: auto;
}

.messages ul {
    overflow: hidden;
    margin-left: 0;
    padding-left: 0;
}
