@import url("https://fonts.googleapis.com/css?family=Lato&display=swap");
body {
  font-family: "Lato", sans-serif;
  color: #444;
  font-size: $font-size-base;
  /*font-family: $sans-serif-family;*/
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
.card-chart {
  .close {
    display: none;
  }
}
.btn-toolbar {
  margin-bottom: 10px;
  button {
    margin-bottom: 0;
  }
}
.full-screen-modal {
  &.modal-dialog {
    max-width: 1100px;
  }
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.5) !important;
}
.danger-images {
  background: #fdeff5;
}

.success-images {
  background: #edfcf5;
}

.row-images {
  background: #f8f8f8;
  padding: 10px;
  min-height: 80px;
  &.selected {
    border-color: $danger-color !important;
  }
  .img-remove {
    top: 0px;
    right: 10px;
  }
}

div.processing {
  .modal-content {
    pointer-events: none;
  }
}
body {
  .tooltip-inner {
    max-width: 200px;
    padding: 3px 10px;
    color: #fff;
    text-align: left;
    background-color: #222222;
    border-radius: 0.25rem;
    box-shadow: 0px 2px 18px #00000029;
    margin-left: 0px;
    font-size: 12px;
    width: auto;
    min-width: auto !important;
    text-transform: capitalize;
  }
  .tooltip.in {
    opacity: 1 !important;
  }
  .tooltip .arrow::before {
    display: none;
  }
}

.form-group .form-control,
.input-group .form-control {
  padding: 10px 10px 10px 10px;
}
.form-control {
  font-size: 0.8rem;
}
textarea.form-control {
  line-height: 1.6;
}
.popover .popover-header {
  color: #111 !important;
  background: #fff !important;
  margin: 0 !important;
  font-size: 12px !important;
}
.main {
  position: relative;
  background: $white-color;
}
.card-default .react-select input {
  border: 0 !important;
  &:focus {
    border: 0 !important;
  }
}
.mapping {
  margin-top: -25px;
}
.d-inline.pdf-icon {
  img {
    width: 50px;
  }
}

.has-activities {
  .popover {
    max-width: 100%;
  }
}

.nav-link {
  cursor: pointer;
  &.active {
    //background: $opacity-1;
    &.dash {
      background: unset;
    }
  }
}
/* Animations */

.nav-pills .nav-link,
.navbar,
.nav-tabs .nav-link,
.sidebar .nav a,
.sidebar .nav a i,
.navbar-collapse .navbar-nav .nav-link,
.animation-transition-general,
.tag,
.tag [data-role="remove"],
.animation-transition-general {
  @include transition($general-transition-time, $transition-ease);
}

//transition for dropdown caret
.dropdown-toggle:after,
.bootstrap-switch-label:before,
.caret {
  @include transition($fast-transition-time, $transition-ease);
}

.dropdown-toggle[aria-expanded="true"]:after,
a[data-toggle="collapse"][aria-expanded="true"] .caret,
.card-collapse .card a[data-toggle="collapse"][aria-expanded="true"] i,
.card-collapse .card a[data-toggle="collapse"].expanded i {
  @include rotate-180();
}

.navbar {
  box-shadow: none !important;
}
button:focus {
  outline: none;
}
.button-bar {
  display: block;
  position: relative;
  width: 22px;
  height: 1px;
  border-radius: 1px;
  background: $white-bg;
  & + .button-bar {
    margin-top: 7px;
  }
  &:nth-child(2) {
    width: 17px;
  }
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid\9;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.btns-mr-5 {
  & .btn {
    margin-right: 5px;
  }
}

.uppercase {
  text-transform: uppercase;
}

.status {
  border-radius: 50%;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  color: #fff;
  display: inline-block;
  margin-right: 5px;
  i {
    font-size: 12px;
  }

  &.ACTIVE,
  &.COMPLETE {
    background: $success-color;
  }

  &.INACTIVE,
  &.REJECTED,
  &.INCOMPLETE {
    background: $danger-color;
  }

  &.SUBMITTED,
  &.ONHOLD,
  &.PROCESSING,
  &.ON_HOLD {
    background: $warning-color;
  }
}

.text-ACTIVE,
.text-COMPLETE {
  color: $success-color;
}

.text-INACTIVE,
.text-REJECTED,
.text-INCOMPLETE {
  color: $danger-color;
}

.text-SUBMITTED,
.text-ONHOLD,
.text-PROCESSING,
.text-ON_HOLD {
  color: $warning-color;
}

h5.qcStatus {
  padding: 5px;
  min-height: 30px;
  font-size: 13px;
  background: white;
  color: $warning-color;
  border: 1px solid $warning-color;
  border: 0;
  border-left: 30px solid $warning-color;
  i {
    vertical-align: middle;
    margin-right: 25px;
    margin-left: -30px;
    color: #fff;
    font-size: 20px;
    float: left;
  }
  &.INACTIVE {
    color: $danger-color;
    border-color: $danger-color;
  }
  &.REJECTED {
    color: $danger-color;
    border-color: $danger-color;
  }
  &.DELETED {
    color: $danger-color;
    border-color: $danger-color;
  }
  &.INCOMPLETE {
    color: $danger-color;
    border-color: $danger-color;
  }
  &.APPROVED,
  &.COMPLETE {
    color: $success-color;
    border-color: $success-color;
  }
  &.ACTIVE {
    color: $success-color;
    border-color: $success-color;
  }
}

.tab-pane {
  h5.qcStatus {
    border-left-width: 5px !important;
  }
  i {
    display: none !important;
  }
}

.page-link {
  color: $primary-color;
}

.page-item.active .page-link {
  background: $primary-color;
  border-color: $primary-color;
}

div[contenteditable="true"]:hover {
  background: #fcf8e1;
}

div[contenteditable="true"]:focus {
  background: #fcf8e1;
  outline: 0;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

ul.select-vertical {
  background: #f7f7f7;
  list-style: none;
  margin: -15px 0 -15px -15px;
  padding: 15px 10px;
  min-height: 100vh;
  height: calc(100% + 15px);
  li {
    text-transform: uppercase;
    color: #aaaaaa;
    font-size: 12px;
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #dfdfdf;
  }
}

ul.add-cat {
  list-style-type: none;
  height: 300px;
  overflow-y: auto;
  margin: 0 0 15px 0px;
  padding: 0px;
  background: #f7f7f7;
  border: 1px solid #ccc;
  li {
    cursor: pointer;
    padding: 7px;
    border-bottom: 1px solid #dfdfdf;
    &.active {
      background: #dedcfd;
    }
  }
  &.mega-menu {
    height: 250px;
  }
}

ul.brand-suggest {
  list-style-type: none;
  height: 300px;
  overflow-y: auto;
  margin: 0px;
  padding: 0px;
  background: #f7f7f7;
  li {
    cursor: pointer;
    padding: 7px;
    border-bottom: 1px solid #dfdfdf;
    &.active {
      background: #deecfd;
    }
  }
}

.sku-form-step {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  text-align: center;
  line-height: 15px;
  color: #fff;
  display: inline-block;
  margin-right: 5px;
  background: #cfd6df;
  vertical-align: baseline;
}

.sku-form-step.active {
  background: green;
}

.sku-form-step i {
  font-size: 8px;
}

.brand-box {
  background: #f7f7f7;
  padding: 30px;
}

.sku-form-box {
  background: #f7f7f7;
  padding: 20px;
  textarea {
    border-radius: 5px;
    border: 1px solid #ddd;
    &:focus {
      border: 1px solid $primary-color;
    }
  }
  label {
    text-transform: capitalize;
    .star {
      color: $danger-color;
    }
  }
  input,
  textarea {
    background: #fff;
    border-color: #ccc;
  }
  .col-sm-9 {
    max-width: 400px;
  }
}

.home-page {
  background: darken($primary-color, 10%);
  a {
    &.nav-link {
      font-size: 0.8rem !important;
      cursor: pointer;
      text-transform: uppercase !important;
    }
  }
}

.home-page {
  .nav-link:not(.btn):hover,
  .nav-link:not(.btn):active {
    background: transparent !important;
  }
}

.product-images {
  background: #f7f7f7;
  min-height: 400px;
  padding: 20px;
}

.done-uploading {
  background: #fff;
  padding: 25px;
  .form-check {
    padding-left: 0px;
  }
}

.not-found-page {
  padding-bottom: 100px;
}

/*
.left-arrow {
  display: inline-block;
  position: relative;
  background: grey;
  padding: 15px;
  margin-top: 20px;
}
.left-arrow:before {
  content: "";
  display: block;
  position: absolute;
  right: 100%;
  top: 50%;
  margin-top: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-right: 10px solid grey;
  border-bottom: 10px solid transparent;
  border-left: 10px solid transparent;
}
*/

.Dropzone {
  min-height: 150px;
  width: 100%;
  background-color: #fff;
  border: 2px dashed rgb(187, 186, 186);
  // border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
}

.Highlight {
  background-color: rgb(188, 185, 236);
}

.Icon {
  opacity: 0.3;
  height: 64px;
  width: 64px;
}

.FileInput {
  display: none;
}

.has-search .input-group .form-control {
  height: 35px !important;
  background: #f5f5f5 !important;
}

.has-search .input-group-append button {
  margin: 0 !important;
  height: 35px !important;
}

.main-panel {
  min-height: 100vh;
}

.loader {
  border: 6px solid #aaa;
  border-radius: 50%;
  border-top: 6px solid $primary-color;
  width: 100px;
  height: 100px;
  margin: 0 auto;
  -webkit-animation: spin 1s linear infinite;
  /* Safari */
  animation: spin 1s linear infinite;
  position: fixed;
  left: 0;
  right: 0;
  top: 35%;
  bottom: 0;
  z-index: 1051;
}

/* Safari */

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.product-images {
  .card-header {
    padding: 0.5rem 1rem !important;
    font-size: 15px;
  }
  .card-body {
    padding: 10px;
    background: #fff;
  }
}

.price-invent {
  background: #f7f7f7;
  min-height: 400px;
  padding: 20px;
  .card-header {
    padding: 0.5rem 1rem !important;
    font-size: 13px;
    border: 1px solid #ccc !important;
  }
  .card-body {
    padding: 10px;
    border: 1px solid #ccc;
    border-top: 0;
  }
}
.samplepage {
  input {
    font-size: 90%;
  }
}

.table {
  font-size: 90%;
}

.table > thead > tr > th {
  font-size: 0.9em !important;
}

.marker-relative {
  position: relative;
  margin-top: 30px;
}

.default-marker {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  margin: 0;
  border-radius: 0;
}

.default--marker {
  line-height: 13px !important;
}

.sort-icon {
  width: 7px;
  height: 12px;
  cursor: pointer;
  margin-left: 5px;
}

ul {
  &.tabs {
    margin-left: 0;
    padding-left: 0;
    border-bottom: 1px solid #ccc;
    li {
      list-style: none;
      font-size: 14px;
      display: inline-block;
      padding: 5px 20px;
      border-radius: 3px 3px 0 0;
      cursor: pointer;
    }
  }
}

ol.breadcrumb {
  background: transparent;
  margin-bottom: 0;
  margin-top: 10px;
  padding-left: 0;
}

#react-select-2-input {
  margin: 0;
  border: 0 !important;
}

input[aria-autocomplete="list"] {
  margin: 0 !important;
  padding: 0 !important;
  border: 0 !important;
}

.c-brand-text {
  min-height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.alert {
  div {
    width: 90%;
  }
}

.badge {
  margin-left: 5px;
}

.star {
  color: red;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.selected-chain {
  .breadcrumb {
    background: #fff !important;
    padding: 10px 15px;
    margin-bottom: 10px;
  }
  &.campaign-page {
    .breadcrumb {
      background: #fff;
    }
  }
}

.selected-tree {
  .active {
    color: $success-color;
    background: transparent;
  }
  .inactive {
    color: $danger-color;
    background: transparent;
  }
}

.highlight {
  animation: colorchange 2s;
  -webkit-animation: colorchange 2s;
}

@keyframes colorchange {
  0% {
    background: $warning-color;
  }
  25% {
    background: lighten($warning-color, 25%);
  }
  50% {
    background: lighten($warning-color, 30%);
  }
  75% {
    background: lighten($warning-color, 55%);
  }
  100% {
    background: lighten($warning-color, 80%);
  }
}

@-webkit-keyframes colorchange {
  0% {
    background: $warning-color;
  }
  25% {
    background: lighten($warning-color, 25%);
  }
  50% {
    background: lighten($warning-color, 30%);
  }
  75% {
    background: lighten($warning-color, 55%);
  }
  100% {
    background: lighten($warning-color, 80%);
  }
}

.new-items {
  background: rgba(221, 152, 152, 0.3);
  padding: 10px;
  margin-bottom: 20px;
}

.brand-suggest {
  &.auto {
    height: auto;
    margin-bottom: 0px;
    clear: both;
  }
}

.register-page,
.login-page {
  margin-top: 50px;
  margin-bottom: 100px;
  min-height: 440px;
}

.info-horizontal {
  padding-top: 20px;
  img {
    margin-top: 5px !important;
    width: 45px;
    float: left;
    margin-right: 15px;
    filter: grayscale(50%);
    -webkit-filter: grayscale(50%);
  }
  .icon {
    margin-top: 5px !important;
    margin-right: 10px !important;
    i {
      font-size: 3em !important;
      color: #555;
    }
  }
  .info-title {
    color: #333;
  }
  .description {
    color: #444;
  }
}

.table {
  width: 99.9%;
}

.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
  padding: 5px !important;
}

.table-striped {
  th {
    cursor: pointer;
  }
}

.list-img {
  width: 50px;
  border: 1px solid #ddd;
}

.btn-group-xs > .btn,
.btn-xs {
  min-height: 22px;
  padding: 2px 7px !important;
  font-size: 0.875rem;
  line-height: 0.5;
  border-radius: 0.2rem;
}

.go-back-top {
  cursor: pointer;
  margin-right: 15px;
  i {
    font-size: 10px;
    margin-right: 5px;
  }
}

.brand-name {
  // border-left: 1px solid #fff;
  // padding-left: 15px;
  display: block;
}

.tool-right {
  float: right;
  margin-top: 8px;
}

.sidebar {
  .nav {
    li > span {
      padding: 5px 8px !important;
    }
  }
}

.list-group-item {
  a {
    display: block;
  }
  &.active {
    background: lighten($primary-color, 35%);
    border: 1px solid rgba(0, 0, 0, 0.125);
  }
}

.table-bordered {
  thead {
    border: 1px solid #dee2e6;
  }
  th {
    background: #fefefe;
    color: #333;
    font-weight: bold !important;
  }
  td {
    a {
      width: 100%;
      display: block;
    }
  }
}

.pipe {
  border-left: 2px solid #eee;
  margin: 0 10px;
}

.listings {
  min-height: calc(100vh - 120px);
}

li {
  &.head {
    background: #ddd;
    border-top: 20px solid #fff;
    &.no-bdr {
      border-top: 0 !important;
    }
  }
}

.pagination {
  margin-bottom: 0 !important;
}

.cat-box {
  background: #dfdfdf;
  padding: 15px 0px;
  margin: 0 0px;
}

.no-data {
  height: 400px;
  vertical-align: middle;
  text-align: center;
  font-size: 20px;
  opacity: 0.4;
}

.kyc-msg {
  margin: -15px -15px 15px -15px;
  border-top: 0;
  border-right: 0;
}

.header__bg {
  background: "";
  // transform: skewY(-6deg);
  // transform-origin: top left;
  width: 100%;
  height: 80%;
  position: absolute;
  top: 60px;
  bottom: 0;
  right: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
}

.backs {
  background: "";
  background-size: cover;
  background-repeat: no-repeat;
  box-shadow: inset 0 0 0 2000px rgba(73, 60, 133, 0.9);
  min-height: 300px;
  h2,
  h5,
  p {
    color: #fff;
  }
}

.main-logo {
  width: 110px;
}

.main-logo-inner {
  width: 80px;
}

.strip {
  background: #fff;
  border-bottom: 1px solid #eee;
  align-items: center;
  padding: 10px;
  img {
    filter: grayscale(100%);
    -webkit-filter: grayscale(100%);
  }
}

.how-it-works {
  border-top: 1px solid #eee;
}

.border-btm {
  border-bottom: 1px dotted #eee;
  margin-bottom: 0;
  padding: 7px 0;
}

h5,
h6 {
  &.text-capitalize {
    overflow: hidden;
    height: 30px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-bottom: 0;
  }
}
h5 {
  &.text-camelcase {
    background: #eee;
    padding: 5px 5px;
    font-size: 15px;
  }
}

.bg-grey {
  background: #f4f7fc !important;
}

#feature img {
  width: 80px;
}

#feature h4 {
  font-size: 1.125rem;
  font-weight: bold;
}

.context-dark,
.bg-gray-dark,
.bg-primary {
  color: rgba(255, 255, 255, 0.8);
}

.no-bdr {
  border: 0 !important;
}

.footer-classic {
  color: #aaa;
  .top-foot {
    padding-bottom: 20px;
    .col-sm-3 {
      border-right: 1px solid darken($primary-color, 15%);
    }
  }
  overflow: hidden;
  background: darken($primary-color, 10%);
  padding: 20px 0 0 0;
  .copy-bar {
    min-height: 60px;
    display: flex;
    align-items: center;
    p {
      margin-bottom: 0;
    }
    a {
      padding-right: 20px;
      color: #aaa;
      font-weight: bold;
      &:hover {
        color: #999;
        text-decoration: none;
      }
    }
    border-top: 1px solid darken($primary-color, 15%);
    text-align: left;
    padding: 10px;
    .rights {
      margin-bottom: 0;
    }
  }
}

.static-h1 {
  padding-top: 30px;
  font-size: 30px;
}

.full-page--content {
  padding-top: 70px;
}

.mini-container {
  height: 100%;
  display: flex;
  justify-content: center;
  padding-left: 0px;
  flex-direction: column;
}

.for-block {
  padding-top: 60px;
  padding-bottom: 60px;
  .col-sm-6 {
    border-right: 0.5px solid #eee;
    border-left: 0.5px solid #eee;
  }
}

.for {
  font-size: 28px;
  text-align: center;
  margin-bottom: 0;
  color: #333;
}

.become-seller {
  background: #000;
  border-radius: 8px;
  color: #fff;
  padding: 13px;
  display: inline-block;
  font-weight: bold;
  &:hover {
    color: #fff;
    text-decoration: none;
  }
}

.four-o-4-text {
  padding-top: 100px;
  font-size: 100px;
}

.bg-darken {
  display: flex;
  align-items: center;
  background: darken($primary-color, 10%);
  /*background-image: linear-gradient(to top left, $primary-color, darken($primary-color, 20%));*/
  min-height: 200px;
  padding: 20px;
  color: white;
  h1 {
    margin-bottom: 5px;
    font-size: 30px;
  }
  h3 {
    margin-bottom: 0;
    font-size: 20px;
  }
}

.bg-video {
  background: #fcfdff;
  padding-bottom: 80px;
  h3 {
    text-align: center;
    margin: 0;
    padding: 10px 0;
    background: #fff;
  }
  h4 {
    font-weight: bold;
    color: #333;
  }
}

.main-box {
  .col-sm-5 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    h1 {
      margin-bottom: 5px;
      font-size: 36px;
      font-weight: bold;
    }
    p {
      margin-top: 30px;
      line-height: 27px;
      margin-bottom: 0;
      font-size: 20px;
    }
  }
}

.main-img {
  margin-top: 20px;
}

body {
  &.colo {
    background: #fcfdff !important;
  }
}

.bl-0 {
  border-left: 0 !important;
}

.br-0 {
  border-right: 0 !important;
}

.video video {
  width: 100%;
  max-height: 100%;
}

.nav-list {
  padding-left: 0;
}

.nav-list li {
  padding-top: 5px;
  padding-bottom: 5px;
}

.nav-list li a:hover:before {
  margin-left: 0;
  opacity: 1;
  visibility: visible;
}

ul,
ol {
  list-style: none;
}

.help-icon {
  width: 12px;
  img {
    width: 10px;
    margin-left: 0px;
  }
}

p {
  &.tool-list {
    margin-bottom: 0;
    text-align: left;
  }
}

.close-box {
  font-size: 30px;
  color: $danger-color;
  font-weight: bold;
  position: absolute;
  right: 15px;
  top: -5px;
  cursor: pointer;
}

.invalid-id {
  text-align: center;
  padding-top: 100px;
  color: $danger-color;
  font-size: 20px;
}

.breadcrumb-item {
  span {
    cursor: pointer;
  }
}

h5 {
  font-size: 1.2rem;
}

.bs-vertical-wizard {
  // border-right: 1px solid #eaecf1;
  padding-bottom: 20px;
}

.bs-vertical-wizard ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.bs-vertical-wizard ul > li {
  display: block;
  position: relative;
}

.bs-vertical-wizard ul > li > span {
  display: block;
  padding: 10px 10px 0px 40px;
  color: #333c4e;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.8px;
}

.bs-vertical-wizard ul > li > span:before {
  content: "";
  position: absolute;
  width: 1px;
  height: calc(100% - 10px);
  background-color: #bdc2ce;
  left: 11px;
  bottom: -14px;
  z-index: 3;
}

.bs-vertical-wizard ul > li > span .ico {
  pointer-events: none;
  font-size: 14px;
  position: absolute;
  left: 10px;
  top: 15px;
  z-index: 2;
}

.bs-vertical-wizard ul > li > span:after {
  content: "";
  position: absolute;
  border: 2px solid #bdc2ce;
  border-radius: 50%;
  top: 14px;
  left: 6px;
  width: 10px;
  height: 10px;
  z-index: 3;
}

.bs-vertical-wizard ul > li > span .desc {
  display: block;
  color: #bdc2ce;
  font-size: 11px;
  font-weight: 400;
  line-height: 1.8;
  letter-spacing: 0.8px;
}

.bs-vertical-wizard ul > li.complete > span:before {
  background-color: #5cb85c;
  opacity: 1;
  height: calc(100% - 25px);
  bottom: -9px;
}

.bs-vertical-wizard ul > li.complete > span:after {
  display: none;
}

.bs-vertical-wizard ul > li.locked > span:after {
  display: none;
}

.bs-vertical-wizard ul > li:last-child > a:before {
  display: none;
}

.bs-vertical-wizard ul > li.complete > span .ico {
  left: 8px;
}

.bs-vertical-wizard ul > li > span .ico.ico-green {
  color: #5cb85c;
}

.bs-vertical-wizard ul > li > span .ico.ico-muted {
  color: #bdc2ce;
}

.bs-vertical-wizard ul > li.current {
  background-color: #fff;
}

.bs-vertical-wizard ul > li.current > span:before {
  background-color: $success-color;
  opacity: 1;
}

.bs-vertical-wizard ul > li.current > span:after {
  border-color: $success-color;
  background-color: $success-color;
  opacity: 1;
}

.bs-vertical-wizard ul > li.current:after,
.bs-vertical-wizard ul > li.current:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.bs-vertical-wizard ul > li.current:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 10px;
  margin-top: -10px;
}

.bs-vertical-wizard ul > li.current:before {
  border-color: rgba(234, 236, 241, 0);
  border-left-color: #eaecf1;
  border-width: 0px;
  margin-top: -11px;
}

.cursor {
  cursor: pointer;
}

.brown::after {
  content: "×";
  position: fixed;
  right: 100px;
  top: 20px;
  font-size: 60px;
  color: red;
  border: 0;
  background: transparent;
  cursor: pointer;
}

.img-remove {
  position: absolute;
  right: 6px;
  top: -17px;
  color: red;
  font-size: 30px;
  opacity: 1;
}

.static-page {
  padding: 20px;
  h5 {
    font-weight: bold;
    text-transform: capitalize;
    font-size: 16px;
  }
  ul {
    li {
      padding: 5px 0;
    }
  }
}

.guidelines {
  cursor: pointer;
  i {
    margin-left: 15px;
  }
  p {
    text-align: right;
    margin-left: 5px;
    display: flex;
    align-items: center;
  }

  &.opened {
    margin-bottom: 10px;
    p {
      margin-bottom: 0;
    }
  }
  &.closed {
    background: transparent;
    ol {
      display: none;
    }
  }
  ol {
    margin-top: 10px;
    background: #fff;
    padding: 10px;
  }
}
.image-box {
  border: 1px solid #ddd;
  .img-thumbnail {
    max-height: 100px;
  }
  .col-sm-2 {
    text-align: center;
    margin: 10px 0px;
  }
  .form-check {
    padding-left: 0;
  }
}

.fixed-actions {
  position: fixed;
  top: 100px;
  background: #fff;
  border-radius: 5px;
  border: 5px solid gray;
  padding: 20px 40px;
  z-index: 99;
  left: 94px;
  width: auto;
  button {
    margin-bottom: 0;
  }
  .cross-icon {
    cursor: pointer;
    top: 5px;
    right: 10px;
    display: block;
    position: absolute;
    i {
      font-size: 20px;
      display: block !important;
    }
  }
}

::-webkit-input-placeholder {
  text-transform: capitalize;
}
.col-sm-4 h4 {
  font-size: 18px;
  padding: 0;
  margin: 5px 0;
}

.format-label {
  display: flex;
  div {
    display: flex;
    flex: 1;
  }
  .color-bar {
    margin-left: 15px;
    height: 20px;
    width: 80px;
  }
}

.text-muted {
  color: #9b9b9b !important;
}
.psmall {
  &.d-flex {
    &.aflx {
      a {
        display: flex;
        height: 100%;
        width: 100%;
        text-decoration: none;
      }
    }
  }
  padding-left: 15px;
  padding-right: 15px;
  &.SUPPLIER {
    .text-muted {
      color: #6c757d !important;
    }
  }
}
.white-space-pre {
  white-space: pre-wrap;
}
.card-flex {
  justify-content: space-between;
  display: flex;
  height: 100%;
  flex-direction: column;
}
.add-new-address {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 100%;
  width: 100%;
  cursor: pointer;
}
tr.active {
  background: rgb(166, 219, 155) !important;
}
.sr-msg {
  height: 240px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  padding-bottom: 0;
}

.mesup {
  .Dropzone {
    min-height: 50px;
  }
  .Icon {
    width: 20px;
    height: 20px;
  }
  span {
    font-size: 14px;
  }
}
.Toastify__toast--success {
  background: $success-color !important;
}

.csv-label {
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

.bg-white {
  .main-panel {
    background: #fff;
  }
}
