.card-login {
  background: white;
  border-radius: 0 !important;

  padding: 0 30px 30px 30px;

  .btn {
    border-radius: 0;
  }
  input[aria-autocomplete="list"] {
    margin: 0 !important;
    padding: 0 !important;
    background: white !important;
  }
  input {
    background: #f2f2f2;
    padding: 15px !important;
    margin: 0 0 15px;
    border-radius: 0;
    border: 0 !important;
  }
  input:focus {
    background: #f2f2f2 !important;
  }

  .input-group:last-child {
    margin-bottom: 20px !important;
  }

  .header {
    margin-left: 20px;
    margin-right: 20px;
    padding: 30px 0;
  }
  .text-divider {
    margin-top: 30px;
    margin-bottom: 0px;
    text-align: center;
  }
  .content {
    padding: 0px 30px;
  }

  .form-check {
    margin-top: 20px;
    padding-left: 0;

    label {
      margin-left: 14px;
      padding-left: 40px;
    }
  }

  .social-line {
    margin-top: 20px;
    text-align: center;

    .btn.btn-icon,
    .btn.btn-icon .btn-icon {
      margin-left: 5px;
      margin-right: 5px;
      box-shadow: 0px 5px 50px 0px rgba(0, 0, 0, 0.2);
    }
  }

  .card-footer {
    margin-bottom: 10px;
    margin-top: 0px;
  }
}

body {
  background: #f5f5f5;
}
