$font-size-base: 0.8rem;
@import "~bootstrap/scss/bootstrap";

@import "styles/variables";
@import "styles/mixins";

// Core CSS
@import "styles/buttons";
@import "styles/social-buttons";
@import "styles/inputs";
@import "styles/typography";
@import "styles/misc";
@import "styles/navbar";
@import "styles/dropdown";
@import "styles/alerts";
@import "styles/images";
@import "styles/nucleo-outline";
@import "styles/tables";
@import "styles/footers";
@import "styles/fixed-plugin";
// components
@import "styles/checkboxes-radio";
@import "styles/progress";
@import "styles/badges";
//@import "styles/pagination";
@import "styles/info-areas";
@import "styles/pills";
// @import "styles/tabs";
@import "styles/rtl";
@import "styles/popups";
@import "styles/modals";
@import "styles/carousel";
@import "styles/sidebar-and-main-panel";
@import "styles/timeline";
@import "styles/fileupload";
// cards
@import "styles/cards";
@import "styles/cards/card-default";
@import "styles/cards/card-profile";
@import "styles/cards/card-login";
@import "styles/cards/card-signup";
@import "styles/cards/card-stats";

/*
@import "styles/cards/card-chart";
@import "styles/cards/card-user";
@import "styles/cards/card-plain";
@import "styles/cards/card-background";
@import "styles/cards/card-collapse";
@import "styles/cards/card-map";
@import "styles/cards/card-contributions";
@import "styles/cards/card-info-area";

@import "styles/cards/card-stats-mini";

@import "styles/cards/card-subcategories";
@import "styles/cards/card-testimonials";
@import "styles/cards/card-wizard";
*/

// Plugins CSS
@import "styles/plugins/plugin-perfect-scrollbar";
@import "styles/plugins/plugin-react-select";

/*
@import "styles/plugins/plugin-animate-bootstrap-notify";
@import "styles/plugins/plugin-bootstrap-switch";
@import "styles/plugins/plugin-card-wizard";
@import "styles/plugins/plugin-datatables.net";
@import "styles/plugins/plugin-nouislider";
@import "styles/plugins/plugin-react-big-calendar";
@import "styles/plugins/plugin-react-bootstrap-sweetalert";
@import "styles/plugins/plugin-react-bootstrap-switch";
@import "styles/plugins/plugin-react-datetime";

@import "styles/plugins/plugin-react-simple-maps";
@import "styles/plugins/plugin-react-table";
@import "styles/plugins/plugin-react-tagsinput";
*/

// example pages and sections
@import "styles/example-pages";
@import "styles/sections";
@import "styles/responsive";
@import "styles/media-queries";
