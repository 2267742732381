.card-default {
  background: white;
  border-radius: 0 !important;
  box-shadow: none;
  padding: 0 30px 30px 30px;

  .btn {
    border-radius: 4px;
  }

  input,
  textarea {
    margin: 0 0 5px;
    border-radius: 4px;
    border: 1px solid rgb(204, 204, 204);
  }
  input:focus,
  textarea:focus {
    border: 1px solid #e3e3e3 !important;
  }

  .react-select__input input {
    padding: 0px !important;
  }

  .input-group:last-child {
    margin-bottom: 20px !important;
  }

  select.form-control {
    height: 40px;
  }

  .header {
    margin-left: 20px;
    margin-right: 20px;
    padding: 30px 0;
  }
  .text-divider {
    margin-top: 30px;
    margin-bottom: 0px;
    text-align: center;
  }
  .content {
    padding: 0px 30px;
  }

  .form-check {
    margin-top: 20px;
    padding-left: 0;

    label {
      margin-left: 14px;
      padding-left: 40px;
    }
  }
  .card-footer {
    margin-bottom: 10px;
    margin-top: 0px;
  }
}

.modal-body .card-default {
  padding: 0;
}
